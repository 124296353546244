<template>
    <v-container fluid>
        <v-form @submit.prevent="getContainerTypeReceipts('search')">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('search_container_type_receipt') }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="container_type_receipt" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="container_type_receipt" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-check"
                                                  :label="$t('container_type_receipt_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="filteredHeaders" :items="container_type_receipts" :options.sync="options"
                              :page.sync="page" :items-per-page="perPage" :server-items-length="totalContainerTypeReceipts"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"  @click="editContainerTypeReceipt(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted" @click="deleteContainerTypeReceipt(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import { mapGetters } from "vuex"
    import { ValidationObserver, ValidationProvider } from 'vee-validate'

    export default {
        name: "ContainerTypeReceipts",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                fab: false,
                phoneRaw: null,
                container_type_receipt: null,
                email: null,
                container_type_receipts: [],
                container_type_receiptItems: [],
                sortBy: "name",
                sortDir: true,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalContainerTypeReceipts: 0,
                options: {},
                loading: false,
                headers: [
                    {
                        text: this.$t('container_type_receipt'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('slug'),
                        align: "left",
                        sortable: true,
                        value: "slug"
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ]
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getContainerTypeReceipts()
                },
                deep: false
            }
        },
        methods: {
            editContainerTypeReceipt(item) {
                this.$router.push({
                    name: 'container_type_receipt.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async deleteContainerTypeReceipt(item) {
                if (confirm(this.$t('delete_container_type_receipt'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/container_type_receipt/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('container_type_receipt_has_been_deleted'))
                            this.getContainerTypeReceipts()
                        })
                        .catch(err => {
                            this.$toastr.success(this.$t('container_type_receipt_has_not_been_deleted'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async getContainerTypeReceipts(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.container_type_receipt) {
                    params.container_type_receipt = this.container_type_receipt;
                }



                await this.$http
                    .get("admin/container_type_receipt", {
                        params: params,
                    })
                    .then(res => {
                        this.container_type_receipts = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalContainerTypeReceipts = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.container_type_receipts = []
                        this.totalContainerTypeReceipts = 0
                        this.$toastr.error(this.$t('failed_to_get_list_container_type_receipts'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        }
    }
</script>
